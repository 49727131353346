import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const MePage = () => (
  <Layout>
    <SEO title="Me" />
    <div className="me-wrapper">
      <div className="wrapper">
        <div className="flexed">
          <h2>Hi, I'm Dave.</h2>
          <h3>I make things on the web.</h3>
          <p>I get by as a contract developer, system admin, property manager, digital consultant, eCommerce entrepreneur, media producer, and music enthusiast living in Denver, Colorado</p>
          <br/>
          <p>You can network with me on <a href="https://github.com/avidsapp">GitHub</a>, <a href="https://linkedin.com/in/avidsapp">LinkedIn</a>, and <a href="https://instagram.com/avidsapp">Instagram</a></p>
          <p>I'm <strike>available for hire</strike>. <Link to="/mail">Drop me a line</Link></p>
          <br/>
          <p>Cheers!</p>
        </div>
      </div>
    </div>
  </Layout>
)

export default MePage
